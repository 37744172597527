export default function IconSolidSearch() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      id="Search--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Search Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.4000000000000004 7.2448a4.8448 4.8448 0 1 1 9.6896 0 4.8448 4.8448 0 0 1 -9.6896 0Zm9.048 5.9008a7.2448 7.2448 0 1 1 1.6960000000000002 -1.6960000000000002L15.648 13.952000000000002a1.2000000000000002 1.2000000000000002 0 1 1 -1.6960000000000002 1.6960000000000002L11.448 13.1456Z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
  );
}
